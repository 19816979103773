<template>
  <div class="home">
    <div class="title">AI智能创作鼠标驱动下载</div>
    <div class="cards">
      <div class="bots">
        <div class="bot1" @click="open()"></div>
        <el-button
          class="bot2"
          type="text"
          @click="dialogVisible = true"
        ></el-button>
        <el-dialog
          top="150px"
          :visible.sync="dialogVisible"
          width="740px"
          :before-close="handleClose"
        >
          <div class="tit-bar">
            <img src="../assets/缺省png.png" alt="" />
            <span>驱动升级中，敬请期待</span>
          </div>
        </el-dialog>
      </div>
      <div @click="turnto()" class="help">
        <img class="img" src="../assets/浏览icon.png" alt="" />
        <div>浏览产品说明书</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getZnsbQd } from '@/api/index'
export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      dialogVisible: false,
      data: []
    };
  },
  mounted() {
    this.getapi()
  },
  methods: {
    getapi() {
      getZnsbQd().then(res => {
        this.data = res.data
        console.log(this.data.doc_url, 'res');
      })
    },
    open() {
      window.location.href = this.data.window_url;
    },
    turnto() {
      console.log(this.data.doc_url, 'res');
      // this.$router.push(this.data.doc_url)
      window.open(this.data.doc_url)
    }
  }
}
</script>

<style  scoped>
.home {
  width: 100%;
  height: 100%;
  background-image: url('../assets/bg.png');
  background-size: 100% 100%;
  position: relative;
}
.zi {
  width: 0px;
  height: 0px;
}
.cards {
  position: relative;
  top: 63%;
  width: 100%;
  height: 200px;
}
.title {
  position: relative;
  top: 61%;
  text-align: center;
  font-size: 30px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #111111;
  line-height: 63px;
}

.bots {
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 50%;
  height: 210px;
}

.bot1 {
  background: url('../assets/windows版.png')no-repeat ;
  background-size: 100%;
  cursor: pointer;
  width: 45%;
  height: 94%;
  cursor: pointer;
}
.bot2 {
  background: url('../assets/Mac版.png')no-repeat;
  background-size: 100%;
  cursor: pointer;
  width: 45%;
  height: 94%;
  cursor: pointer;
}
.tit-bar {
  width: 480px;
  height: 508px;
  margin: 20px 130px 20px 130px;
  text-align: center;
  font-size: 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 42px;
}
.help {
  display: flex;
  margin: auto;
  box-sizing: border-box;
  padding: 10px 16px;
  width: 170px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(25, 37, 51, 0.1);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  font-size: 15px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
  cursor: pointer;
}
.img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
/* .bot1:hover {
  height: 180px;
  line-height: 80px;
}

.bot2:hover {
  height: 180px;
  line-height: 80px;
} */
</style>
