// 不加密的请求方式
import axios from 'axios'
import { baseUrl } from './baseUrl'

/**
 * 判断当前是否为生产环境
 */
// const isProduction = process.env.NODE_ENV === 'production'

/** baseURL */
// const baseURL = isProduction ? baseUrl : '/proxy'
const baseURL = baseUrl 


/** 文件上传路径 */
// export const uploadUrl = `${baseURL}/design/oss/file`

// axios默认配置
// 超时时间
axios.defaults.timeout = 1000 * 60
// 默认地址
axios.defaults.baseURL = baseURL

// http request 拦截器
// axios.interceptors.request.use(
//   config => {
//     return config
//   },
//   error => {
//     return Promise.reject(error.response)
//   }
// )

// http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (!window.navigator.onLine) {
      error = {
        errCode: 0,
        message: '暂无网络'
      }
    }
    return Promise.reject(error.response || error) // 返回接口返回的错误信息
  }
)

export default axios
